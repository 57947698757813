import React from 'react';
import styles from './SectionMerchantGrid.module.scss';
import MerchantsGridWithAccordion from './MerchantsGridWithAccordion';

export default function SectionMerchantGrid() {
  return (
    <section className={styles.section}>
      <p className={styles.title1}>
        アプリ<span className={styles.linearText}>1000万</span>ダウンロード
        <span className={styles.linearText}>ありがとう！</span>
      </p>
      <p className={styles.title2}>
        最大全額キャッシュバック
        <br />
        キャンペーン対象ショップ
      </p>
      <MerchantsGridWithAccordion notShowButtonBottom />
    </section>
  );
}

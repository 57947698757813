import { createArray } from 'src/utils';

export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-0.png'),
    destinationURL: 'https://jp.shein.com/',
  },
  {
    id: 1,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-1.png'),
    destinationURL: 'https://special.qoo10.jp/DynamicAD/4172/',
  },
  {
    id: 2,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-2.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 3,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-3.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 4,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-4.png'),
    destinationURL: 'https://shop-list.com/women/',
  },
  {
    id: 5,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-5.png'),
    destinationURL:
      'https://www.2ndstreet.jp/buy?utm_source=paidy.com&utm_medium=referral&utm_campaign=20230703paidyCP',
  },
  {
    id: 6,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-6.png'),
    destinationURL: 'https://www.magaseek.com/top/index/tp_1',
  },
  {
    id: 7,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-7.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  {
    id: 8,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-8.png'),
    destinationURL: 'https://yz-store.com/',
  },
  {
    id: 9,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-9.png'),
    destinationURL: 'http://s.runway-ch.jp/rc_230703_paidy',
  },
  {
    id: 10,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-10.png'),
    destinationURL: 'https://dazzystore.com',
  },
  {
    id: 11,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-11.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 12,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-12.png'),
    destinationURL:
      'https://d-rw.com/?gclid=Cj0KCQiAorKfBhC0ARIsAHDzslvaJFCZphITzFzN6YF836n0bBGsICzFMuAbNOiseWQE9V7_Q2Qs_24aAuGdEALw_wcB',
  },
  {
    id: 13,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-13.png'),
    destinationURL:
      'https://gladd.jp/?t=women&pcode=paidy_benefit&utm_source=paidy&utm_medium=benefit&utm_campaign=benefit_paidy_cp23summer&utm_content=benefit_paidy',
  },
  {
    id: 14,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-14.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 15,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-15.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 16,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-16.png'),
    destinationURL: 'https://nairo.jp/',
  },
  {
    id: 17,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-17.png'),
    destinationURL: 'https://wego.jp/',
  },
  {
    id: 18,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-18.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  {
    id: 19,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-19.png'),
    destinationURL: 'https://casio.link/43R9eTF',
  },
  {
    id: 20,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-20.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 21,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-21.png'),
    destinationURL: 'https://edwin-mall.jp',
  },
  {
    id: 22,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-22.png'),
    destinationURL: 'https://esica.shop/',
  },
  {
    id: 23,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-23.png'),
    destinationURL: 'https://www.y-aoyama.jp/',
  },
  {
    id: 24,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-24.png'),
    destinationURL: 'https://punyus.jp/',
  },
  {
    id: 25,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-25.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 26,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-26.png'),
    destinationURL: 'https://247lingerie.co/',
  },
  {
    id: 27,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-27.png'),
    destinationURL:
      'https://www.axes-net.com/?utm_source=paidy&utm_medium=referral&utm_campaign=paidycamp2307',
  },
  {
    id: 28,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-28.png'),
    destinationURL: 'https://soir5cocotteparis.com/',
  },
  {
    id: 29,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-29.png'),
    destinationURL: 'http://www.artidaoud.com/',
  },
  {
    id: 30,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-30.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 31,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-31.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2304',
  },
  {
    id: 32,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-32.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 33,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-33.png'),
    destinationURL: 'https://proactiv.co.jp/redirect.aspx?reid=1972975607',
  },
  {
    id: 34,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-34.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
  },
  {
    id: 35,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-35.png'),
    destinationURL: 'https://morecon.jp/',
  },
  {
    id: 36,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-36.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 37,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-37.png'),
    destinationURL: 'https://proactiv.co.jp/redirect.aspx?reid=1972975607',
  },
  {
    id: 38,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-38.png'),
    destinationURL: 'https://jp.shein.com/',
  },
  {
    id: 39,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-39.png'),
    destinationURL: 'https://special.qoo10.jp/DynamicAD/4172/',
  },
  {
    id: 40,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-40.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 41,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-41.png'),
    destinationURL: 'https://shop-list.com/women/',
  },
  {
    id: 42,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-42.png'),
    destinationURL: 'https://www.magaseek.com/top/index/tp_1',
  },
  {
    id: 43,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-43.png'),
    destinationURL:
      'https://gladd.jp/?t=women&pcode=paidy_benefit&utm_source=paidy&utm_medium=benefit&utm_campaign=benefit_paidy_cp23summer&utm_content=benefit_paidy',
  },
  {
    id: 44,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-44.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 45,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-45.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 46,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-46.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  {
    id: 47,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-47.png'),
    destinationURL: 'https://jp.17.live/',
  },
  {
    id: 48,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-48.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  {
    id: 49,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-49.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 50,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-50.png'),
    destinationURL: 'https://www.mbga.jp/',
  },
  {
    id: 51,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-51.png'),
    destinationURL: 'https://www.withlive.jp/',
  },
  {
    id: 52,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-52.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 53,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-53.png'),
    destinationURL: 'https://www.nicovideo.jp/',
  },
  {
    id: 54,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-54.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 55,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-55.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 56,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-56.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 57,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-57.png'),
    destinationURL: 'https://gold.razer.com/jp/ja',
  },
  {
    id: 58,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-58.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 59,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-59.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 60,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-60.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 61,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-61.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 62,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-62.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2304',
  },
  {
    id: 63,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-63.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 64,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-64.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 65,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-65.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
  },
  {
    id: 66,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-66.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy_230210',
  },
  {
    id: 67,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-67.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
  },
  {
    id: 68,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-68.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 69,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-69.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 70,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-70.png'),
    destinationURL: 'https://casio.link/43R9eTF',
  },
  {
    id: 71,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-71.png'),
    destinationURL:
      'https://www.furusato-tax.jp/?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=paidy_703',
  },
  {
    id: 72,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-72.png'),
    destinationURL: 'https://jp.shein.com/',
  },
  {
    id: 73,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-73.png'),
    destinationURL:
      'https://kurand.jp/?utm_source=kurand&utm_medium=pr_ext_paidyCP0703&utm_campaign=8896___',
  },
  {
    id: 74,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-74.png'),
    destinationURL: 'https://lotte-shop.jp/shop/',
  },
  {
    id: 75,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-75.png'),
    destinationURL:
      'https://www.furusato-tax.jp/?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=paidy_703',
  },
  {
    id: 76,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-76.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 77,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-77.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  {
    id: 78,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-78.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  {
    id: 79,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-79.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 80,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-80.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 81,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-81.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy_230210',
  },
  {
    id: 82,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-82.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 83,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-83.png'),
    destinationURL: 'https://www.withlive.jp/',
  },
  {
    id: 84,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-84.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 85,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-85.png'),
    destinationURL: 'https://edwin-mall.jp',
  },
  {
    id: 86,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-86.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 87,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol1/merchants/logos/logo-87.png'),
    destinationURL: 'https://jp.shein.com/',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: createArray(0, 34),
  },
  {
    categoryValue: 2,
    merchantIds: createArray(35, 44),
  },
  {
    categoryValue: 3,
    merchantIds: createArray(45, 57),
  },
  {
    categoryValue: 4,
    merchantIds: createArray(58, 72),
  },
  {
    categoryValue: 5,
    merchantIds: createArray(73, 75),
  },
  {
    categoryValue: 6,
    merchantIds: createArray(76, 87),
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: 'ゲーム・動画・ライブ配信など' },
  { value: 4, label: '家電・インテリア' },
  { value: 5, label: 'グルメ' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
